import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import dashBFFRequestApi from '../Configs/dashBFFRequest'
import {
  MARKETPLACE_REQUEST,
  MARKETPLACE_SLUG_REQUEST,
  MARKETPLACE_SLUG_ERROR,
  MARKETPLACE_SUCCESS,
  MARKETPLACE_ERROR,
  MARKETPLACE_DETAILS_REQUEST,
  MARKETPLACE_DETAILS_SUCCESS,
  MARKETPLACE_DETAILS_ERROR,
  MARKETPLACE_TREE_REQUEST,
  MARKETPLACE_TREE_SUCCESS,
  MARKETPLACE_TREE_ERROR
} from '../Constants/ActionTypes'
import { getCustomByMarketplace, getSlug } from './custom'
import { getApiSettings } from 'src/Actions/settings'
import appConfig from 'src/Configs/appConfig'
import { isLocal } from 'src/Utils/Utils'

export const getMarketplaceBySlug = (slug, isSlugRequest) => {
  const request = () => {
    return {
      type: isSlugRequest ? MARKETPLACE_SLUG_REQUEST : MARKETPLACE_REQUEST
    }
  }
  const success = (data) => {
    return { type: MARKETPLACE_SUCCESS, ...data }
  }
  const failure = (error) => {
    return {
      type: isSlugRequest ? MARKETPLACE_SLUG_ERROR : MARKETPLACE_ERROR,
      error
    }
  }

  return (dispatch) => {
    dispatch(request())
    const urlRequest = uriRequests.getMarketplaceIdBySlug.replace(
      '{slugName}',
      slug
    )
    dashBFFRequestApi
      .get(urlRequest, {
        ignoreAuthorization: true,
        ignoreSellerAndMarketplaceContext: true
      })
      .then((response) => {
        if (!response.data) {
          dispatch(failure({ message: 'Marketplace não encontrado' }))
        } else {
          const { data: marketplaceData } = response
          let data

          if (marketplaceData) {
            const { marketplace_id: id, name } = marketplaceData

            data = {
              slug,
              id,
              name
            }

            if (id) {
              dispatch(getCustomByMarketplace(id))
            }
          }

          if (isSlugRequest) {
            if (isLocal()) {
              window.location.href = `http://${slug}.${appConfig.domain}:8080`
            } else {
              window.location.href = `https://${slug}.${appConfig.domain}`
            }
          } else {
            dispatch(success(data))
          }
        }
      })
      .catch((error) => {
        dispatch(failure(error.message || error))
      })
  }
}

export const getMarketplaceDetails = () => {
  const request = () => {
    return { type: MARKETPLACE_DETAILS_REQUEST }
  }
  const success = (details) => {
    return { type: MARKETPLACE_DETAILS_SUCCESS, details }
  }
  const failure = (error) => {
    return { type: MARKETPLACE_DETAILS_ERROR, error }
  }

  return (dispatch) => {
    dispatch(request())

    const urlRequest = uriRequests.getMarketplaceDetails
    requestApi
      .get(urlRequest)
      .then((marketplaceResponse) => {
        if (marketplaceResponse.data) {
          const { id: marketplaceId } = marketplaceResponse.data

          dispatch(getCustomByMarketplace(marketplaceId))
          dispatch(getApiSettings())
          dispatch(getSlug(marketplaceId))
          dispatch(success(marketplaceResponse.data))
        } else {
          throw new Error('no_data')
        }
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
}

export const getMarketplaceTree = () => {
  const request = () => {
    return { type: MARKETPLACE_TREE_REQUEST }
  }
  const success = (tree) => {
    return { type: MARKETPLACE_TREE_SUCCESS, tree }
  }
  const failure = (error) => {
    return { type: MARKETPLACE_TREE_ERROR, error }
  }

  return (dispatch, getState) => {
    dispatch(request())

    const {
      user: { id: userId }
    } = getState()

    const urlRequest = uriRequests.getMarketplaceTree.replace(
      '{userId}',
      userId
    )

    requestApi
      .get(urlRequest, {
        ignoreSellerAndMarketplaceContext: true
      })
      .then((response) => {
        if (response.data) {
          const { items } = response.data
          dispatch(success(items))
        } else {
          throw new Error('no_data')
        }
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
}
