import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import takeError from '../Errors/takeError'
import createToast from '../Utils/createToast'
import merchantCategoryCodeError from '../Errors/merchantCategoryCodeError'
import {
  MERCHANT_CODES_REQUESTING,
  MERCHANT_CODES_SUCCESS,
  MERCHANT_CODES_ERROR
} from '../Constants/ActionTypes'

export const getCategoryCodes = () => {
  const request = () => ({ type: MERCHANT_CODES_REQUESTING })
  const success = data => ({ type: MERCHANT_CODES_SUCCESS, data })
  const failure = error => ({ type: MERCHANT_CODES_ERROR, error })

  return dispatch => {
    dispatch(request())

    const urlRequest = uriRequests.getMerchantCodes

    requestApi.get(urlRequest, {
      params: {
        limit: 500
      }
    })
      .then(res => {
        dispatch(success(res.data.items))
      })
      .catch(error => {
        const e = takeError(error, merchantCategoryCodeError)
        createToast('error', e.message, e.status)
        dispatch(failure(e))
      })
  }
}
