import _ from 'lodash'
import moment from 'moment'
import {
  TRANSFERS_SUCCEEDED_DETAIL_REQUEST,
  TRANSFERS_SUCCEEDED_DETAIL_SUCCESS,
  TRANSFERS_SUCCEEDED_DETAIL_ERROR,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS,
  TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR,
  KPI_CHANGE_PERIOD,
  TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR,
  TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST,
  TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS,
  TRANSACTIONS_SUCCEEDED_DETAIL_ERROR,
  TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST,
  TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR,
  TRANSACTIONS_FAILED_AMOUNT_REQUEST,
  TRANSACTIONS_FAILED_AMOUNT_SUCCESS,
  TRANSACTIONS_FAILED_AMOUNT_ERROR,
  TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST,
  TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS,
  TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR,
  TRANSACTIONS_DISPUTED_AMOUNT_REQUEST,
  TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS,
  TRANSACTIONS_DISPUTED_AMOUNT_ERROR,
  TRANSACTIONS_DISPUTED_DETAIL_REQUEST,
  TRANSACTIONS_DISPUTED_DETAIL_SUCCESS,
  TRANSACTIONS_DISPUTED_DETAIL_ERROR,
  TRANSACTIONS_FAILED_DETAIL_REQUEST,
  TRANSACTIONS_FAILED_DETAIL_SUCCESS,
  TRANSACTIONS_FAILED_DETAIL_ERROR,
  TRANSACTIONS_AVERAGE_TICKET_REQUEST,
  TRANSACTIONS_AVERAGE_TICKET_SUCCESS,
  TRANSACTIONS_AVERAGE_TICKET_ERROR,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS,
  TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR,
  TRANSFERS_TO_THE_MARKETPLACE_REQUEST,
  TRANSFERS_TO_THE_MARKETPLACE_SUCCESS,
  TRANSFERS_TO_THE_MARKETPLACE_ERROR,
  TRANSFERS_FAILED_REQUEST,
  TRANSFERS_FAILED_SUCCESS,
  TRANSFERS_FAILED_ERROR,
  TRANSFERS_FAILED_DETAIL_REQUEST,
  TRANSFERS_FAILED_DETAIL_SUCCESS,
  TRANSFERS_FAILED_DETAIL_ERROR,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS,
  TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR,
  SELLERS_BY_STATUS_REQUEST,
  SELLERS_BY_STATUS_SUCCESS,
  SELLERS_BY_STATUS_ERROR,
  COMMISSION_PROGRESSION_REQUEST,
  COMMISSION_PROGRESSION_SUCCESS,
  COMMISSION_PROGRESSION_ERROR,
  ALL_TOGETHER_KPIS_REQUEST,
  ALL_TOGETHER_KPIS_SUCCESS
} from 'src/Constants/ActionTypes'
import uriRequests from 'src/Configs/uriRequests'
import { getRangeDateForPeriod } from '../Utils/getRangeDateForPeriod'
import takeError from '../Errors/takeError'
import kpiErrors from '../Errors/kpiErrors'

import { getLastCommission } from './commissions'
import {
  translateTransferTypesForGraph,
  translateTransactionsTypes,
  getPaymentType,
  parseDateHistogram
} from 'src/Utils/Utils'
import metricsRequestApi from 'src/Configs/metricsRequest'

export const getTransfersSucceededForPeriod = () => {
  const request = () => ({ type: TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST })
  const success = (data) => ({
    type: TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
            transfers(aggregate:
            {
                sum: {
                    field: amount
                },
                count: {
                    field: id
                }
            },
            filter: {
                created_at: {
                    gte: "${gte}",
                    lte: "${lte}"
                },
                status_payment: "succeeded"
             })
        }`
    }

    const uri = uriRequests.getMetrics
    const requestTransfersSucceededForPeriod = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransfersSucceededForPeriod
      .then((response) => {
        const transfersData = response.data.data.transfers
        const transfersSucceeded = {
          amount: transfersData.sum_amount,
          count: transfersData.count
        }
        dispatch(success(transfersSucceeded))
      })
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const changePeriod = (period) => (dispatch) => {
  dispatch({ type: KPI_CHANGE_PERIOD, period })
}

export const getTransactionsSucceededAmount = () => {
  const request = () => ({ type: TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    dispatch(request())

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate:
          {
              sum: {
                  field: amount
              },
              top_hits: {
                field: seller_name
                aggregate: {
                  sum: {
                    field: amount
                  }
                }
              }
          },
          filter: {
              created_at: {
                  lte: "${lte}",
                  gte: "${gte}"
              },
              payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
              status: "succeeded"
          })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestGetTransactionsSucceededAmount = metricsRequestApi.post(uri, {
      ...query
    })

    return requestGetTransactionsSucceededAmount
      .then(
        ({
          data: {
            data: {
              transactions: { sum_amount: sumAmount, top_hits: topHits }
            }
          }
        }) => {
          dispatch(
            success({
              amount: sumAmount / 100,
              topHitsSellers: topHits.slice(0, 5)
            })
          )
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionSucceededDetailCreditInstallments = async (
  period
) => {
  try {
    const { gte, lte } = getRangeDateForPeriod(period)

    const queryCashCredit = {
      query: `{
        transactions(aggregate: {
            count: {
              field: id
            }
            sum: {
              field: amount
            }
          },
          filter: {
            status: "succeeded",
            payment_type: "credit"
            number_of_installments: {
              lt: 2
            }
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    const queryInstallmentCredit = {
      query: `{
        transactions(aggregate: {
            count: {
              field: id
            }
            sum: {
              field: amount
            }
          },
          filter: {
            status: "succeeded",
            payment_type: "credit"
            number_of_installments: {
              gt: 1
            }
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    const uri = uriRequests.getMetrics

    const cashCreditResponse = {
      sumAmount: 0,
      count: 0
    }

    try {
      const requestGetTransactionsSucceededDetailCashCredit =
        await metricsRequestApi.post(uri, {
          ...queryCashCredit
        })

      cashCreditResponse.sumAmount =
        requestGetTransactionsSucceededDetailCashCredit.data.data.transactions.sum_amount
      cashCreditResponse.count =
        requestGetTransactionsSucceededDetailCashCredit.data.data.transactions.count
    } catch (e) {
      if (e.response && e.response.status !== 404) throw e
    }

    const installmentCreditResponse = {
      sumAmount: 0,
      count: 0
    }

    try {
      const requestGetTransactionsSucceededDetailInstallmentCredit =
        await metricsRequestApi.post(uri, {
          ...queryInstallmentCredit
        })

      installmentCreditResponse.sumAmount =
        requestGetTransactionsSucceededDetailInstallmentCredit.data.data.transactions.sum_amount
      installmentCreditResponse.count =
        requestGetTransactionsSucceededDetailInstallmentCredit.data.data.transactions.count
    } catch (e) {
      if (e.response && e.response.status !== 404) throw e
    }

    const groupedByCredit = []
    let value1, value2

    value1 = cashCreditResponse.sumAmount / 100
    value2 = cashCreditResponse.count

    if (value1) {
      groupedByCredit.push({
        name: 'Crédito à vista',
        value1,
        value2
      })
    }

    value1 = installmentCreditResponse.sumAmount / 100
    value2 = installmentCreditResponse.count

    if (value1) {
      groupedByCredit.push({
        name: 'Crédito parcelado',
        value1,
        value2
      })
    }

    return groupedByCredit
  } catch (e) {
    throw e
  }
}

export const getTransactionsSucceededDetail = (hasCardTransactions = true) => {
  const request = () => ({ type: TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_SUCCEEDED_DETAIL_ERROR,
    error
  })

  return async (dispatch, getState) => {
    try {
      const {
        kpi: { period }
      } = getState()

      dispatch(request())

      const { gte, lte, format, interval } = getRangeDateForPeriod(period)

      const query = {
        query: `{
        transactions(aggregate:
          {
              sum: {
                  field: amount
              },
              count: {
                  field: id
              },
              date_histogram: {
                  field: created_at
                  format: "${format}"
                  interval: "${interval}"
                  aggregate: {
                      sum: {
                        field: amount
                      }
                  }
              },
              grouped_by: {
                field: payment_type
                aggregate: {
                  sum: {
                    field: amount
                  }
                }
              }
              top_hits: {
                ${hasCardTransactions ? `payment_method: { field: card_brand }` : `field: seller_name`}
                aggregate: {
                  sum: {
                    field: amount
                  }
                }
              }
          },
          filter: {
              created_at: {
                  lte: "${lte}",
                  gte: "${gte}"
              },
              payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
              status: "succeeded"
          })
      }`
      }

      const uri = uriRequests.getMetrics

      const data = {
        grouped: [],
        count: null,
        amount: null,
        historic: [],
        topHits: []
      }

      try {
        const {
          data: {
            data: {
              transactions: {
                grouped_by: groupedBy,
                count,
                sum_amount: sumAmount,
                date_histogram_created_at: dateHistogram,
                top_hits: topHits
              }
            }
          }
        } = await metricsRequestApi.post(uri, {
          ...query
        })

        data.grouped = groupedBy
        data.count = count
        data.amount = sumAmount
        data.historic = dateHistogram
        data.topHits = topHits
      } catch (e) {
        if (e.response && e.response.status !== 404) throw e
      }

      let credit = []
      try {
        credit = await getTransactionSucceededDetailCreditInstallments(period)
      } catch (e) {
        if (e.response && e.response.status !== 404) throw e
      }

      const groupedBy = []
      data.grouped.map(
        ({ key: name, count, sum_amount: { value: amount } }) => {
          if (name !== 'credit') {
            groupedBy.push({
              name: getPaymentType(name),
              value1: amount / 100,
              value2: count
            })
          }
        }
      )
      groupedBy.push(...credit)

      const histogram = parseDateHistogram(data.historic, period)

      const topHitsMap = data.topHits.map((topHit) => {
        const { value, count: topHitCount } = topHit
        topHit.percent = (topHitCount / data.count) * 100
        topHit.value = value / 100
        return topHit
      })

      return dispatch(
        success({
          amount: data.amount / 100,
          count: data.count,
          histogram: histogram,
          groupedBy: groupedBy,
          topHits: topHitsMap
        })
      )
    } catch (e) {
      dispatch(failure(takeError(e, kpiErrors)))
    }
  }
}

export const getTransactionsFailedAmount = () => {
  const request = () => ({ type: TRANSACTIONS_FAILED_AMOUNT_REQUEST })
  const success = (data) => ({ type: TRANSACTIONS_FAILED_AMOUNT_SUCCESS, data })
  const failure = (error) => ({ type: TRANSACTIONS_FAILED_AMOUNT_ERROR, error })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
        }, filter: {
          status: "failed"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsFailedAmount = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsFailedAmount
      .then(
        ({
          data: {
            data: {
              transactions: { sum_amount: amount }
            }
          }
        }) => {
          dispatch(success({ amount: amount / 100 }))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsFailedDetail = () => {
  const request = () => ({ type: TRANSACTIONS_FAILED_DETAIL_REQUEST })
  const success = (data) => ({ type: TRANSACTIONS_FAILED_DETAIL_SUCCESS, data })
  const failure = (error) => ({ type: TRANSACTIONS_FAILED_DETAIL_ERROR, error })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
          count: {
            field: id
          }
          grouped_by: {
            field: payment_type
            aggregate: {
              sum: {
                field: amount
              }
            }
          }
        }, filter: {
          status: "failed"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsFailedDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsFailedDetail
      .then(
        ({
          data: {
            data: {
              transactions: {
                sum_amount: sumAmount,
                count,
                grouped_by: grouped
              }
            }
          }
        }) => {
          const amount = sumAmount / 100
          const groupedBy = grouped.map(
            ({ key, count: value, sum_amount: { value: amount } }) => {
              return {
                name: translateTransactionsTypes(key),
                value: amount / 100,
                value1: amount / 100,
                value2: value
              }
            }
          )
          dispatch(
            success({
              amount,
              count,
              groupedBy
            })
          )
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsChargedBackAmount = () => {
  const request = () => ({ type: TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
        }, filter: {
          status: "charged_back"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsChargedBackAmount = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsChargedBackAmount
      .then(
        ({
          data: {
            data: {
              transactions: { sum_amount: amount }
            }
          }
        }) => {
          dispatch(success({ amount: amount / 100 }))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsChargedBackDetail = () => {
  const request = () => ({ type: TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte, format, interval } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
          count: {
            field: id
          }
          date_histogram: {
            field: created_at
            format: "${format}"
            interval: "${interval}"
            aggregate: {
              sum: {
                field: amount
              }
            }
          }
          top_hits: {
            field: seller_name
            aggregate: {
              sum: {
                field: amount
              }
            }
          }
        }, filter: {
          status: "charged_back"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsChargedBackDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsChargedBackDetail
      .then(
        ({
          data: {
            data: {
              transactions: {
                sum_amount: sumAmount,
                count,
                top_hits: topHits,
                date_histogram_created_at: historic
              }
            }
          }
        }) => {
          const amount = sumAmount / 100
          const top3Hits = topHits.slice(0, 3)
          const topHitsMap = top3Hits.map((top) => {
            top.percent = (top.value / sumAmount) * 100
            return top
          })

          dispatch(
            success({
              amount,
              count,
              historic,
              topHits: topHitsMap
            })
          )
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsDisputedAmount = () => {
  const request = () => ({ type: TRANSACTIONS_DISPUTED_AMOUNT_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_DISPUTED_AMOUNT_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
        }, filter: {
          status: "dispute",
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}",
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsDisputedAmount = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsDisputedAmount
      .then(
        ({
          data: {
            data: {
              transactions: { sum_amount: amount }
            }
          }
        }) => {
          dispatch(success({ amount: amount / 100 }))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsDisputedDetail = () => {
  const request = () => ({ type: TRANSACTIONS_DISPUTED_DETAIL_REQUEST })
  const success = (data) => ({
    type: TRANSACTIONS_DISPUTED_DETAIL_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSACTIONS_DISPUTED_DETAIL_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte, format, interval } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          sum: {
            field: amount
          }
          count: {
            field: id
          }
          date_histogram: {
            field: created_at
            format: "${format}"
            interval: "${interval}"
            aggregate: {
              sum: {
                field: amount
              }
            }
          }
          top_hits: {
            field: seller_name
            aggregate: {
              sum: {
                field: amount
              }
            }
          }
        }, filter: {
          status: "dispute"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsDisputedDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransactionsDisputedDetail
      .then(
        ({
          data: {
            data: {
              transactions: {
                sum_amount: sumAmount,
                count,
                top_hits: topHits,
                date_histogram_created_at: historic
              }
            }
          }
        }) => {
          const amount = sumAmount / 100
          const top3Hits = topHits.slice(0, 3)
          const topHitsMap = top3Hits.map((top) => {
            top.percent = (top.value / sumAmount) * 100
            return top
          })

          dispatch(
            success({
              amount,
              count,
              historic,
              topHits: topHitsMap
            })
          )
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsAverageTicket = () => {
  const request = () => ({ type: TRANSACTIONS_AVERAGE_TICKET_REQUEST })
  const success = (averageTicketAmount) => ({
    type: TRANSACTIONS_AVERAGE_TICKET_SUCCESS,
    averageTicketAmount
  })
  const failure = (error) => ({
    type: TRANSACTIONS_AVERAGE_TICKET_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transactions(aggregate: {
          avg: {
            field: amount
          }
        }, filter: {
          status: "succeeded"
          payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet", "nupay", "bolepix"],
          created_at: {
            gte: "${gte}"
            lte: "${lte}"
          }
        })
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransactionsAverageTicket = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    requestTransactionsAverageTicket
      .then(
        ({
          data: {
            data: {
              transactions: { avg_amount: averageTicketAmount }
            }
          }
        }) => dispatch(success(averageTicketAmount / 100))
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransfersToTheMarketplace = () => {
  const request = () => ({ type: TRANSFERS_TO_THE_MARKETPLACE_REQUEST })
  const success = (amount) => ({
    type: TRANSFERS_TO_THE_MARKETPLACE_SUCCESS,
    amount
  })
  const failure = (error) => ({
    type: TRANSFERS_TO_THE_MARKETPLACE_ERROR,
    error
  })

  return async (dispatch, getState) => {
    const {
      context: { seller },
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transfers(
          aggregate: {
            sum: {
              field: amount_payment
            }
          },
          filter: {
            seller_id: "${seller?.id}",
            status_payment: "succeeded",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    dispatch(request())

    try {
      const uri = uriRequests.getMetrics
      const {
        data: {
          data: {
            transfers: { sum_amount_payment: amount }
          }
        }
      } = await metricsRequestApi.post(uri, {
        ...query
      })

      dispatch(success(amount))
    } catch (error) {
      dispatch(failure(takeError(error, kpiErrors)))
    }
  }
}

export const getTransfersFailed = () => {
  const request = () => ({ type: TRANSFERS_FAILED_REQUEST })
  const success = (amount) => ({ type: TRANSFERS_FAILED_SUCCESS, amount })
  const failure = (error) => ({ type: TRANSFERS_FAILED_ERROR, error })

  return async (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transfers(
          aggregate: {
            sum: {
              field: amount_payment
            }
          },
          filter: {
            status_payment: "failed",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    dispatch(request())

    try {
      const uri = uriRequests.getMetrics
      const {
        data: {
          data: {
            transfers: { sum_amount_payment: amount }
          }
        }
      } = await metricsRequestApi.post(uri, {
        ...query
      })

      dispatch(success(amount))
    } catch (error) {
      dispatch(failure(takeError(error, kpiErrors)))
    }
  }
}

export const getTransfersToTheMarketplaceDetail = () => {
  const request = () => ({ type: TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST })
  const success = (data) => ({
    type: TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS,
    data
  })
  const failure = (error) => ({
    type: TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR,
    error
  })

  return (dispatch, getState) => {
    const {
      context: { seller },
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transfers(aggregate: {
            sum: {
              field: amount_payment
            },
            count: {
              field: id
            },
            grouped_by: {
              field: transfer_type
              aggregate: {
                sum: {
                  field: amount_payment
                }
              }
            }
          },
          filter: {
            confirmed: "1",
            seller_id: "${seller?.id}",
            status_payment: "succeeded",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            },
            must_not: {
              transfer_type: ["payout_zoop", "first_cash_in", "cash_in", "adjustment"],
              installment: "1"
            }
          }
        )
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransfersToTheMarketplace = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransfersToTheMarketplace
      .then(
        ({
          data: {
            data: {
              transfers: {
                grouped_by: grouped,
                count,
                sum_amount_payment: amount
              }
            }
          }
        }) => {
          const aux = grouped.map(
            ({ key, count: value, sum_amount_payment: { value: amount } }) => {
              return {
                name: translateTransferTypesForGraph(key),
                value1: amount,
                value2: value
              }
            }
          )

          const groupedBy = _(aux)
            .groupBy('name')
            .map((objs, key) => ({
              name: key,
              value1: _.sumBy(objs, 'value1'),
              value2: _.sumBy(objs, 'value2')
            }))
            .value()

          const transfersToTheMarketplaceData = {
            amount,
            count,
            groupedBy
          }

          dispatch(success(transfersToTheMarketplaceData))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransfersFailedDetail = () => {
  const request = () => ({ type: TRANSFERS_FAILED_DETAIL_REQUEST })
  const success = (data) => ({ type: TRANSFERS_FAILED_DETAIL_SUCCESS, data })
  const failure = (error) => ({ type: TRANSFERS_FAILED_DETAIL_ERROR, error })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transfers(aggregate: {
            sum: {
              field: amount_payment
            },
            count: {
              field: id
            },
            grouped_by: {
              field: transfer_type
              aggregate: {
                sum: {
                  field: amount_payment
                }
              }
            }
          },
          filter: {
            status_payment: "failed",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            },
            must_not: {
              transfer_type: ["payout_zoop", "first_cash_in", "cash_in", "adjustment"]
          }
        )
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransfersFailedDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransfersFailedDetail
      .then(
        ({
          data: {
            data: {
              transfers: {
                grouped_by: grouped,
                count,
                sum_amount_payment: totalAmount
              }
            }
          }
        }) => {
          const aux = grouped.map(
            ({ key, count: value, sum_amount_payment: { value: amount } }) => {
              return {
                name: translateTransferTypesForGraph(key),
                value1: amount,
                value2: value
              }
            }
          )

          const groupedBy = _(aux)
            .groupBy('name')
            .map((objs, key) => ({
              name: key,
              value1: _.sumBy(objs, 'value1'),
              value2: _.sumBy(objs, 'value2')
            }))
            .value()

          dispatch(
            success({
              amount: totalAmount,
              count,
              groupedBy
            })
          )
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransfersSucceededDetail = () => {
  const request = () => ({ type: TRANSFERS_SUCCEEDED_DETAIL_REQUEST })
  const success = (data) => ({ type: TRANSFERS_SUCCEEDED_DETAIL_SUCCESS, data })
  const failure = (error) => ({ type: TRANSFERS_SUCCEEDED_DETAIL_ERROR, error })

  return (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte, format, interval } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        transfers(aggregate: {
            sum: {
              field: amount
            },
            count: {
              field: id
            },
            date_histogram: {
              field: created_at
              format: "${format}"
              interval: "${interval}"
              aggregate: {
                sum: {
                  field: amount
                }
              }
            },
            grouped_by: {
              field: transfer_type
              aggregate: {
                sum: {
                  field: amount
                }
              }
            }
          },
          filter: {
            confirmed: "1",
            status_payment: "succeeded",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            },
            must_not: {
              transfer_type: ["payout_zoop", "first_cash_in", "cash_in", "adjustment"]
            }
          }
        )
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransfersSucceededDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransfersSucceededDetail
      .then(
        ({
          data: {
            data: {
              transfers: {
                count,
                grouped_by: grouped,
                sum_amount: amount,
                date_histogram_created_at: historic
              }
            }
          }
        }) => {
          const aux = grouped.map(
            ({ key, count: value, sum_amount: { value: amount } }) => {
              return {
                name: translateTransferTypesForGraph(key),
                value1: amount,
                value2: value
              }
            }
          )

          const groupedBy = _(aux)
            .groupBy('name')
            .map((objs, key) => ({
              name: key,
              value1: _.sumBy(objs, 'value1'),
              value2: _.sumBy(objs, 'value2')
            }))
            .value()

          historic = historic.map(({ key, sum_amount: { value }, count }) => ({
            key,
            count,
            sum_amount: {
              value: value * 100
            }
          }))

          const transfersSucceededDetail = {
            amount,
            count,
            historic,
            groupedBy
          }
          dispatch(success(transfersSucceededDetail))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getTransactionsSucceededAmountBySeller = (seller) => {
  const request = () => ({
    type: TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST
  })
  const success = (amount) => ({
    type: TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS,
    amount
  })
  const failure = (error) => ({
    type: TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR,
    error
  })

  return (dispatch) => {
    const { gte, lte } = getRangeDateForPeriod(0)

    const query = {
      query: `{
        transactions(aggregate: {
            sum: {
              field: amount
            },
          }, filter: {
            status: "succeeded",
            payment_type: ["voucher", "boleto", "pix", "debit", "credit", "wallet","nupay", "bolepix"],
            on_behalf_of: "${seller.id}",
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    const uri = uriRequests.getMetrics
    const requestTransfersSucceededDetail = metricsRequestApi.post(uri, {
      ...query
    })

    dispatch(request())

    return requestTransfersSucceededDetail
      .then(
        ({
          data: {
            data: {
              transactions: { sum_amount: amount }
            }
          }
        }) => {
          dispatch(success(amount / 100))
        }
      )
      .catch((error) => dispatch(failure(takeError(error, kpiErrors))))
  }
}

export const getSellersByStatus = (status) => {
  const request = () => ({ type: SELLERS_BY_STATUS_REQUEST, status })
  const success = (count) => ({
    type: SELLERS_BY_STATUS_SUCCESS,
    count,
    status
  })
  const failure = (error) => ({ type: SELLERS_BY_STATUS_ERROR, error, status })

  return async (dispatch, getState) => {
    const {
      kpi: { period }
    } = getState()

    const { gte, lte } = getRangeDateForPeriod(period)

    const query = {
      query: `{
        sellers(
          aggregate: {
            count: {
              field: id
            }
          },
          filter: {
            status: "${status}",
            type: ["individual", "business"],
            created_at: {
              gte: "${gte}",
              lte: "${lte}"
            }
          }
        )
      }`
    }

    dispatch(request())

    try {
      const uri = uriRequests.getMetrics
      const {
        data: {
          data: {
            sellers: { count }
          }
        }
      } = await metricsRequestApi.post(uri, {
        ...query
      })

      dispatch(success(count))
    } catch (error) {
      dispatch(failure(takeError(error, kpiErrors)))
    }
  }
}

export const getCommissionProgression = () => {
  const request = () => ({ type: COMMISSION_PROGRESSION_REQUEST })
  const success = (data) => ({ type: COMMISSION_PROGRESSION_SUCCESS, data })
  const failure = (error) => ({ type: COMMISSION_PROGRESSION_ERROR, error })

  return async (dispatch, getState) => {
    try {
      const {
        marketplace: {
          details: {
            customer: { id: sellerOwnerId }
          }
        }
      } = getState()

      dispatch(request())

      const lte = moment().endOf('day')

      let gte = moment('Mai/2020', 'MMM/YYYY').startOf('day')
      const diffMonths = lte.diff(gte, 'months', true)

      if (diffMonths > 12) {
        gte = moment().startOf('day').subtract(1, 'year')
      }

      const query = {
        query: `{
          transactions(
              aggregate: {
                date_histogram: {
                  field: created_at
                  format: "YYYY-MM"
                  interval: "1M"
                  aggregate: {
                    sum: {
                      field: amount
                    }
                  }
                }
              },
              filter: {
                  created_at: {
                      gte: "${gte.format('YYYY-MM-DDTHH:mm:ss')}"
                      lte: "${lte.format('YYYY-MM-DDTHH:mm:ss')}"
                  }
                  payment_type: "commission"
              }
          )
        }`
      }

      const uri = uriRequests.getMetricsBySeller.replace(
        '{sellerId}',
        sellerOwnerId
      )

      const {
        data: {
          data: {
            transactions: { date_histogram_created_at: historic }
          }
        }
      } = await metricsRequestApi.post(
        uri,
        {
          ...query
        },
        {
          bypassSellerContext: true
        }
      )

      const histogram = parseDateHistogram(historic, 1, true)

      return dispatch(
        success({
          historic: histogram
        })
      )
    } catch (e) {
      dispatch(failure(takeError(e, kpiErrors)))
    }
  }
}

export const allTogether = (hasCardTransactions = true) => {
  const request = () => ({ type: ALL_TOGETHER_KPIS_REQUEST })
  const success = () => ({ type: ALL_TOGETHER_KPIS_SUCCESS })

  return async (dispatch, getState) => {
    const {
      seller,
      commissions: { lastCommission }
    } = getState()

    dispatch(request())

    if (!lastCommission) dispatch(getLastCommission())

    await Promise.all([
      dispatch(getCommissionProgression()),
      dispatch(getTransactionsSucceededAmount()),
      dispatch(getTransactionsFailedAmount()),
      dispatch(getTransactionsAverageTicket()),
      dispatch(getTransactionsChargedBackAmount()),
      dispatch(getTransactionsDisputedAmount()),
      dispatch(getTransactionsSucceededDetail(hasCardTransactions)),
      dispatch(getTransactionsFailedDetail()),
      dispatch(getTransactionsSucceededAmountBySeller(seller))
    ])

    dispatch(success())
  }
}
